import { BORDER_PROPERTIES } from './type';
import { matchesDataCell, matchesDataOpCell, matchesFilterCell, getFilterOpStyles, matchesHeaderCell, getHeaderOpStyles } from 'dash-table/conditional';
import { traverse2 } from 'core/math/matrixZipMap';
function resolveEdges(styles, priority) {
    let res = {};
    traverse2(styles, BORDER_PROPERTIES, (s, p, i) => {
        const border = s.style[p] || s.style.border;
        if (border) {
            res[p] = [border, priority ?? i];
        }
    });
    return res;
}
export const getDataCellEdges = (datum, i, column, active, selected, priority) => (styles) => resolveEdges(matchesDataCell(datum, i, column, active, selected)(styles), priority);
export const getDataOpCellEdges = (datum, i) => (styles) => resolveEdges(matchesDataOpCell(datum, i)(styles));
export const getFilterCellEdges = (column) => (styles) => resolveEdges(matchesFilterCell(column)(styles));
export const getFilterOpCellEdges = () => (styles) => resolveEdges(getFilterOpStyles(styles));
export const getHeaderCellEdges = (i, column) => (styles) => resolveEdges(matchesHeaderCell(i, column)(styles));
export const getHeaderOpCellEdges = (i) => (styles) => resolveEdges(getHeaderOpStyles(i)(styles));
