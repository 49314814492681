import * as R from 'ramda';
import Logger from 'core/Logger';
import lexer from 'core/syntax-tree/lexer';
import syntaxer from 'core/syntax-tree/syntaxer';
function toStructure(tree) {
    const { block, left, lexeme, right, value } = tree;
    const res = {
        subType: lexeme.subType,
        type: lexeme.type,
        value: lexeme.present ? lexeme.present(tree) : value
    };
    if (block) {
        res.block = toStructure(block);
    }
    if (left) {
        res.left = toStructure(left);
    }
    if (right) {
        res.right = toStructure(right);
    }
    return res;
}
export default class SyntaxTree {
    constructor(lexicon, query, postProcessor = res => res) {
        this.lexicon = lexicon;
        this.query = query;
        this.evaluate = (target) => {
            if (!this.isValid) {
                const msg = `DataTable filtering syntax is invalid for query: ${this.query}`;
                Logger.error(msg);
                throw new Error(msg);
            }
            return this.tree && this.tree.lexeme && this.tree.lexeme.evaluate ?
                this.tree.lexeme.evaluate(target, this.tree) :
                true;
        };
        this.filter = (targets) => {
            return targets.filter(this.evaluate);
        };
        this.lexerResult = postProcessor(lexer(this.lexicon, this.query));
        this.syntaxerResult = syntaxer(this.lexerResult);
    }
    get isValid() {
        return this.syntaxerResult.valid;
    }
    get tree() {
        return this.syntaxerResult.tree;
    }
    toQueryString() {
        return this.lexerResult.valid ?
            R.map(l => l.lexeme.transform ? l.lexeme.transform(l.value) : l.value, this.lexerResult.lexemes).join(' ') :
            '';
    }
    toStructure() {
        if (!this.isValid || !this.syntaxerResult.tree) {
            return null;
        }
        return toStructure(this.syntaxerResult.tree);
    }
}
