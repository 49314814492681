import React, { PureComponent } from 'react';
export default class CellLabel extends PureComponent {
    render() {
        const { className, value } = this.props;
        return (React.createElement("div", { ref: 'el', className: className, tabIndex: -1 }, typeof value === 'boolean' ?
            value.toString() :
            value));
    }
    componentDidUpdate() {
        this.setFocus();
    }
    componentDidMount() {
        this.setFocus();
    }
    setFocus() {
        const { active, applyFocus } = this.props;
        if (!active) {
            return;
        }
        const el = this.refs.el;
        if (applyFocus && el && document.activeElement !== el) {
            window.getSelection()?.selectAllChildren(el);
            el.focus();
        }
    }
}
