import * as R from 'ramda';
import React from 'react';
import { memoizeOne } from 'core/memoizer';
import memoizerCache from 'core/cache/memoizer';
import { Presentation } from 'dash-table/components/Table/props';
import Cell from 'dash-table/components/Cell';
import derivedCellEventHandlerProps, { Handler } from 'dash-table/derived/cell/eventHandlerProps';
import isActiveCell from 'dash-table/derived/cell/isActive';
import { shallowClone } from 'core/math/matrixZipMap';
export default (propsFn) => new Wrappers(propsFn);
class Wrappers {
    constructor(propsFn, handlers = derivedCellEventHandlerProps(propsFn)) {
        this.handlers = handlers;
        this.partialGet = memoizeOne((columns, data, offset) => R.addIndex(R.map)((_, rowIndex) => R.addIndex(R.map)((column, columnIndex) => this.getWrapper(false, false, rowIndex + offset.rows, columnIndex, column), columns), data));
        /**
         * Returns the wrapper for each cell in the table.
         */
        this.get = memoizeOne((wrappers, offset, activeCell, selectedCells) => {
            wrappers = shallowClone(wrappers);
            const cells = selectedCells.length ?
                selectedCells :
                activeCell ? [activeCell] : [];
            R.forEach(({ row: i, column: j }) => {
                i -= offset.rows;
                j -= offset.columns;
                if (i < 0 || j < 0 || wrappers.length <= i || wrappers[i].length <= j) {
                    return;
                }
                const w = wrappers[i][j];
                const active = isActiveCell(activeCell, i + offset.rows, j + offset.columns);
                wrappers[i][j] = React.cloneElement(w, {
                    className: w.props.className + ' cell--selected' + (active ? ' focused' : '')
                });
            }, cells);
            return wrappers;
        });
        /**
         * Returns the wrapper for a cell.
         */
        this.wrapper = memoizerCache()((active, className, columnIndex, columnId, rowIndex, onEnter, onLeave, onMove, onClick, onDoubleClick) => (React.createElement(Cell, { active: active, attributes: {
                'data-dash-column': columnId,
                'data-dash-row': rowIndex
            }, className: className, key: `column-${columnIndex}`, onClick: onClick, onDoubleClick: onDoubleClick, onMouseEnter: onEnter, onMouseLeave: onLeave, onMouseMove: onMove })));
    }
    getWrapper(active, selected, rowIndex, columnIndex, column) {
        const isDropdown = column.presentation === Presentation.Dropdown;
        const className = 'dash-cell' +
            ` column-${columnIndex}` +
            (active ? ' focused' : '') +
            (selected ? ' cell--selected' : '') +
            (isDropdown ? ' dropdown' : '');
        return this.wrapper.get(rowIndex, columnIndex)(active, className, columnIndex, column.id, rowIndex, this.handlers(Handler.Enter, rowIndex, columnIndex), this.handlers(Handler.Leave, rowIndex, columnIndex), this.handlers(Handler.Move, rowIndex, columnIndex), this.handlers(Handler.Click, rowIndex, columnIndex), this.handlers(Handler.DoubleClick, rowIndex, columnIndex));
    }
}
