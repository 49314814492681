import React, { PureComponent } from 'react';
import DOM from 'core/browser/DOM';
import { memoizeOne } from 'core/memoizer';
import Markdown from 'dash-table/utils/Markdown';
export default class CellMarkdown extends PureComponent {
    constructor(props) {
        super(props);
        this.getMarkdown = memoizeOne((value, _ready) => ({
            dangerouslySetInnerHTML: {
                __html: Markdown.render(String(value))
            }
        }));
        if (Markdown.isReady !== true) {
            Markdown.isReady.then(() => { this.setState({}); });
        }
    }
    componentDidUpdate() {
        this.setFocus();
    }
    componentDidMount() {
        this.setFocus();
    }
    render() {
        const { className, value } = this.props;
        return (React.createElement("div", Object.assign({ ref: 'el', className: [className, 'cell-markdown'].join(' ') }, this.getMarkdown(value, Markdown.isReady))));
    }
    setFocus() {
        const { active, applyFocus } = this.props;
        if (!active) {
            return;
        }
        const el = this.refs.el;
        if (applyFocus && el && document.activeElement !== el) {
            // Limitation. If React >= 16 --> Use React.createRef instead to pass parent ref to child
            const tdParent = DOM.getFirstParentOfType(el, 'td');
            if (tdParent && tdParent.className.indexOf('phantom-cell') !== -1) {
                tdParent.focus();
            }
        }
    }
}
