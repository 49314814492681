import * as R from 'ramda';
import React from 'react';
import { matrixMap2, matrixMap3 } from 'core/math/matrixZipMap';
import { arrayMap2 } from 'core/math/arrayZipMap';
import derivedCellWrappers from 'dash-table/derived/cell/wrappers';
import derivedCellContents from 'dash-table/derived/cell/contents';
import derivedCellOperations from 'dash-table/derived/cell/operations';
import { derivedDataOpStyles, derivedDataStyles, derivedPartialDataStyles } from 'dash-table/derived/cell/wrapperStyles';
import derivedDropdowns from 'dash-table/derived/cell/dropdowns';
import { derivedRelevantCellStyles } from 'dash-table/derived/style';
import { memoizeOne } from 'core/memoizer';
import memoizerCache from 'core/cache/memoizer';
export default class CellFactory {
    constructor(propsFn, cellContents = derivedCellContents(propsFn), cellDropdowns = derivedDropdowns(), cellOperations = derivedCellOperations(), dataPartialStyles = derivedPartialDataStyles(), dataStyles = derivedDataStyles(), dataOpStyles = derivedDataOpStyles(), cellWrappers = derivedCellWrappers(propsFn), relevantStyles = derivedRelevantCellStyles()) {
        this.propsFn = propsFn;
        this.cellContents = cellContents;
        this.cellDropdowns = cellDropdowns;
        this.cellOperations = cellOperations;
        this.dataPartialStyles = dataPartialStyles;
        this.dataStyles = dataStyles;
        this.dataOpStyles = dataOpStyles;
        this.cellWrappers = cellWrappers;
        this.relevantStyles = relevantStyles;
        this.getCells = memoizeOne((opCells, dataCells) => arrayMap2(opCells, dataCells, (o, c) => o.length ? o.concat(c) : c));
        this.getDataOpCell = memoizerCache()((operation, style, borderBottom, borderLeft, borderRight, borderTop) => {
            return React.cloneElement(operation, {
                style: R.mergeAll([
                    { borderBottom, borderLeft, borderRight, borderTop },
                    style,
                    operation.props.style
                ])
            });
        });
        this.getDataOpCells = memoizeOne((ops, styles, edges) => matrixMap2(ops, styles, (o, s, i, j) => {
            const edge = edges && edges.getStyle(i, j);
            return this.getDataOpCell.get(i, j)(o, s, edge && edge.borderBottom, edge && edge.borderLeft, edge && edge.borderRight, edge && edge.borderTop);
        }));
        this.getDataCell = memoizerCache()((wrapper, content, style, borderBottom, borderLeft, borderRight, borderTop) => {
            return React.cloneElement(wrapper, {
                children: [content],
                style: R.merge(style, { borderBottom, borderLeft, borderRight, borderTop })
            });
        });
        this.getDataCells = memoizeOne((wrappers, contents, styles, edges) => matrixMap3(wrappers, styles, contents, (w, s, c, i, j) => {
            const edge = edges && edges.getStyle(i, j);
            return this.getDataCell.get(i, j)(w, c, s, edge && edge.borderBottom, edge && edge.borderLeft, edge && edge.borderRight, edge && edge.borderTop);
        }));
    }
    get props() {
        return this.propsFn();
    }
    createCells(dataEdges, dataOpEdges) {
        const { active_cell, applyFocus, dropdown_conditional, dropdown, data, dropdown_data, id, is_focused, loading_state, row_deletable, row_selectable, selected_cells, selected_rows, setProps, style_cell, style_cell_conditional, style_data, style_data_conditional, virtualized, visibleColumns } = this.props;
        const relevantStyles = this.relevantStyles(style_cell, style_data, style_cell_conditional, style_data_conditional);
        const partialCellStyles = this.dataPartialStyles(visibleColumns, relevantStyles, virtualized.data, virtualized.offset);
        const cellStyles = this.dataStyles(partialCellStyles, visibleColumns, relevantStyles, virtualized.data, virtualized.offset, active_cell, selected_cells);
        const dataOpStyles = this.dataOpStyles((row_selectable ? 1 : 0) + (row_deletable ? 1 : 0), relevantStyles, virtualized.data, virtualized.offset);
        const dropdowns = this.cellDropdowns(visibleColumns, virtualized.data, virtualized.indices, dropdown_conditional, dropdown, dropdown_data);
        const operations = this.cellOperations(id, data, virtualized.data, virtualized.indices, row_selectable, row_deletable, selected_rows, setProps);
        const partialCellWrappers = this.cellWrappers.partialGet(visibleColumns, virtualized.data, virtualized.offset);
        const cellWrappers = this.cellWrappers.get(partialCellWrappers, virtualized.offset, active_cell, selected_cells);
        const partialCellContents = this.cellContents.partialGet(visibleColumns, virtualized.data, virtualized.offset, !!is_focused, dropdowns, loading_state);
        const cellContents = this.cellContents.get(partialCellContents, active_cell, applyFocus || false, visibleColumns, virtualized.data, virtualized.offset, !!is_focused, dropdowns, loading_state);
        const ops = this.getDataOpCells(operations, dataOpStyles, dataOpEdges);
        const cells = this.getDataCells(cellWrappers, cellContents, cellStyles, dataEdges);
        return this.getCells(ops, cells);
    }
}
