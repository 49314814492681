import { Remarkable } from 'remarkable';
import LazyLoader from 'dash-table/LazyLoader';
export default class Markdown {
    static async loadhljs() {
        Markdown.hljs = await LazyLoader.hljs;
        Markdown.hljsResolve();
        Markdown.isReady = true;
    }
}
Markdown.isReady = new Promise(resolve => {
    Markdown.hljsResolve = resolve;
});
Markdown.render = (value) => {
    return Markdown.md.render(value);
};
Markdown.md = new Remarkable({
    highlight: (str, lang) => {
        if (Markdown.hljs) {
            if (lang && Markdown.hljs.getLanguage(lang)) {
                try {
                    return Markdown.hljs.highlight(lang, str).value;
                }
                catch (err) { }
            }
            try {
                return Markdown.hljs.highlightAuto(str).value;
            }
            catch (err) { }
        }
        else {
            Markdown.loadhljs();
        }
        return '';
    },
    linkTarget: '_blank'
});
