import * as R from 'ramda';
import React, { PureComponent } from 'react';
import { Remarkable } from 'remarkable';
import { isEqual } from 'core/comparer';
import { MAX_32BITS } from 'dash-table/derived/table/tooltip';
import { TooltipSyntax } from 'dash-table/tooltips/props';
export var Arrow;
(function (Arrow) {
    Arrow["Bottom"] = "bottom";
    Arrow["Left"] = "left";
    Arrow["Right"] = "right";
    Arrow["Top"] = "top";
})(Arrow || (Arrow = {}));
export default class Tooltip extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            md: new Remarkable()
        };
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { delay, duration } = nextProps.tooltip;
        if (isEqual(R.omit(['arrow'], this.props), R.omit(['arrow'], nextProps))) {
            return;
        }
        this.setState({
            display: false,
            displayTooltipId: Boolean(clearTimeout(this.state.displayTooltipId)) ||
                setTimeout(() => this.setState({ display: true }), delay),
            hideTooltipId: Boolean(clearTimeout(this.state.hideTooltipId)) ||
                setTimeout(() => this.setState({ display: false }), Math.min(delay + duration, MAX_32BITS))
        });
    }
    render() {
        const { arrow, className } = this.props;
        const { type, value } = this.props.tooltip;
        const { md } = this.state;
        if (!type || !value) {
            return null;
        }
        const props = type === TooltipSyntax.Text ?
            { children: value } :
            { dangerouslySetInnerHTML: { __html: md.render(value) } };
        const { display } = this.state;
        return (React.createElement("div", { className: 'dash-tooltip', "data-attr-anchor": arrow, style: { visibility: (display ? 'visible' : 'hidden') } },
            React.createElement("div", Object.assign({ className: className }, props))));
    }
}
