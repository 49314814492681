import * as R from 'ramda';
import React from 'react';
import Logger from 'core/Logger';
import { arrayMap, arrayMap2 } from 'core/math/arrayZipMap';
import memoizerCache from 'core/cache/memoizer';
import { memoizeOne } from 'core/memoizer';
import ColumnFilter from 'dash-table/components/Filter/Column';
import { TableAction } from 'dash-table/components/Table/props';
import derivedFilterStyles, { derivedFilterOpStyles } from 'dash-table/derived/filter/wrapperStyles';
import derivedHeaderOperations from 'dash-table/derived/header/operations';
import { derivedRelevantFilterStyles } from 'dash-table/derived/style';
import { updateColumnFilter } from 'dash-table/derived/filter/map';
const NO_FILTERS = [];
export default class FilterFactory {
    constructor(propsFn) {
        this.propsFn = propsFn;
        this.filterStyles = derivedFilterStyles();
        this.filterOpStyles = derivedFilterOpStyles();
        this.relevantStyles = derivedRelevantFilterStyles();
        this.headerOperations = derivedHeaderOperations();
        this.onChange = (column, map, operator, setFilter, ev) => {
            Logger.debug('Filter -- onChange', column.id, ev.target.value && ev.target.value.trim());
            const value = ev.target.value.trim();
            updateColumnFilter(map, column, operator, value, setFilter);
        };
        this.filter = memoizerCache()((column, index, map, operator, setFilter) => {
            const ast = map.get(column.id.toString());
            return (React.createElement(ColumnFilter, { key: `column-${index}`, className: `dash-filter column-${index}`, columnId: column.id, isValid: !ast || ast.isValid, setFilter: this.onChange.bind(this, column, map, operator, setFilter), value: ast && ast.query }));
        });
        this.wrapperStyles = memoizeOne((styles, edges) => arrayMap(styles, (s, j) => R.merge(s, edges && edges.getStyle(0, j))));
        this.getCells = memoizeOne((opCells, filterCells) => [opCells.concat(filterCells)]);
        this.getFilterCells = memoizeOne((filters, styles, edges) => arrayMap2(filters, styles, (f, s, j) => React.cloneElement(f, {
            style: R.mergeAll([
                edges && edges.getStyle(0, j),
                s,
                f.props.style
            ])
        })));
        this.getOpFilterCells = memoizeOne((ops, styles, edges) => arrayMap2(ops, styles, (o, s, j) => React.cloneElement(o, {
            style: R.mergeAll([
                edges && edges.getStyle(0, j),
                s,
                o.props.style
            ])
        })));
    }
    get props() {
        return this.propsFn();
    }
    createFilters(filterEdges, filterOpEdges) {
        const { filter_action, map, row_deletable, row_selectable, setFilter, style_cell, style_cell_conditional, style_filter, style_filter_conditional, visibleColumns } = this.props;
        if (filter_action.type === TableAction.None) {
            return NO_FILTERS;
        }
        const relevantStyles = this.relevantStyles(style_cell, style_filter, style_cell_conditional, style_filter_conditional);
        const wrapperStyles = this.wrapperStyles(this.filterStyles(visibleColumns, relevantStyles), filterEdges);
        const opStyles = this.filterOpStyles(1, (row_selectable ? 1 : 0) + (row_deletable ? 1 : 0), relevantStyles)[0];
        const filters = R.addIndex(R.map)((column, index) => {
            return this.filter.get(column.id, index)(column, index, map, filter_action.operator, setFilter);
        }, visibleColumns);
        const styledFilters = this.getFilterCells(filters, wrapperStyles, filterEdges);
        const operations = this.headerOperations(1, row_selectable, row_deletable)[0];
        const operators = this.getOpFilterCells(operations, opStyles, filterOpEdges);
        return this.getCells(operators, styledFilters);
    }
}
